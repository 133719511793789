import React, { useEffect, useRef, useState } from 'react';
import { Popover, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner, faBell } from '@fortawesome/free-solid-svg-icons';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import {
  DELETE_NOTI,
  GET_LIST_NOTI,
  NOTIFICATION_ADDED_SUBSCRIPTION,
  UPDATE_NOTI,
  UPDATE_NOTI_OPENED_TIME,
} from '../../queries';
import moment from 'moment';
import IconTrash from '../svg/IconTrash';
import { useRouter } from 'next/router';

const Notification = () => {
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [itemIndex, setItemIndex] = useState<number>();
  const [listNoti, setListNoti] = useState<NotiData[]>([]);
  const [page, setPage] = useState(0);
  const [countUnread, setCountUnread] = useState<number>(0);
  const endRef = useRef(false);
  const router = useRouter();

  const toggle = () => setIsOpenNotification(!isOpenNotification);

  const [getListNoti, { loading: loadingNoti }] = useLazyQuery<NotiResponse, { skip: number; take: number }>(
    GET_LIST_NOTI,
    {
      onCompleted: (data: NotiResponse) => {
        const notifications = data.getNotifications.listNotification;
        if (notifications.length < 8) {
          endRef.current = true;
          // setPage(prev => prev - 1)
        }
        setListNoti(prev => prev.concat(notifications.filter(notification => notification.actor)));
        setCountUnread(data.getNotifications.totalUnseen);
      },
    },
  );

  const [updateNoti] = useMutation<UpdateNotiResponse, { id: number }>(UPDATE_NOTI, {
    onCompleted: (data: UpdateNotiResponse) => {
      console.log('updateNoti', data.updateReadNotification);
    },
    onError: err => {
      console.log('updateNoti', err.message);
      setListNoti(prev => {
        const newState = [...prev];
        newState[itemIndex || 0] = {
          ...newState[itemIndex || 0],
          is_read: 0,
        };
        return newState;
      });
    },
  });

  const [deleteNoti] = useMutation<{ deleteNotification: NotiData }, { id: number }>(DELETE_NOTI, {
    onCompleted: data => {
      console.log('deleteNoti', data.deleteNotification);
      // setListNoti(prev => prev.filter(item => item.id !== data.deleteNotification.id));
    },
    onError: err => {
      console.log('deleteNoti', err.message);
    },
  });

  const { data: uploadData, loading, error } = useSubscription<SubNotiResponse, never>(
    NOTIFICATION_ADDED_SUBSCRIPTION,
    {
      onSubscriptionData: data => {
        // console.log('onSubscriptionData', data.subscriptionData.data?.notificationAdded)
        // getListNoti({ variables: { skip: 0, take: listNoti.length + 1 } })
        setCountUnread(prev => prev + 1);
        setListNoti(prev => {
          const newState = [...prev];
          if (!data.subscriptionData.data?.notificationAdded) return newState;
          newState.unshift(data.subscriptionData.data?.notificationAdded);
          return newState;
        });
      },
    },
  );

  const [updateNotiOpenTime] = useMutation<never, never>(UPDATE_NOTI_OPENED_TIME, {});

  useEffect(() => {
    getListNoti({ variables: { skip: page * 8, take: (page + 1) * 8 } });
  }, [page]);

  useEffect(() => {
    const itemScroll = document.getElementById('detect-scroll');
    if (itemScroll) {
      const handleScroll = () => {
        if (itemScroll.scrollHeight - itemScroll.scrollTop === itemScroll.clientHeight) {
          // console.log("bottom")
          loadMore();
        }
      };
      itemScroll.addEventListener('scroll', handleScroll, { passive: false });
      return () => itemScroll?.removeEventListener('scroll', handleScroll);
    }
  }, [isOpenNotification]);

  function loadMore() {
    const loading = document.getElementById('loading');
    if (loading || endRef.current) return;
    setPage(prev => prev + 1);
  }

  function onClickItemNoti(noti: NotiData) {
    if (noti.is_read === 1) return;

    setCountUnread(prev => prev - 1);

    setListNoti(prev => {
      const newState = [...prev];
      const idx = prev.findIndex(item => item.id == noti.id);

      if (idx === -1) return prev;

      newState[idx] = {
        ...newState[idx],
        is_read: 1,
      };

      return newState;
    });

    updateNoti({ variables: { id: parseInt(noti.id) } });
  }

  function onClickDeleteNoti(noti: NotiData) {
    setListNoti(prev => prev.filter(item => item.id !== noti.id));

    deleteNoti({
      variables: {
        id: parseInt(noti.id),
      },
    });
  }

  function parseAction(action: string) {
    switch (action) {
      case 'REACTION':
        return 'added a reaction to your project';
      case 'COMMENT':
        return 'added a comment to your project';
      case 'PROJECT_VIEWED':
        return 'invited you to view their project';
      case 'PROJECT_EDITED':
        return 'invited you to edit project';
      case 'ASSIGN':
        return 'assigned a comment to you';
      default:
        return '';
    }
  }

  return (
    <div id="noti-popup">
      <div
        onClick={() => {
          setCountUnread(0);
          if (!isOpenNotification) {
            updateNotiOpenTime();
          }
        }}
      >
        {countUnread > 0 && (
          <div
            className="con-number-noti"
            style={{ width: countUnread.toString().length === 1 ? 12 : countUnread.toString().length === 2 ? 18 : 24 }}
          >
            {countUnread > 99 ? '99+' : countUnread}
          </div>
        )}
        <img src="/img/icon-bell.png" style={{ width: 16, height: 16 }} />
      </div>
      <Popover
        className="container-notification"
        trigger="legacy"
        placement="bottom-end"
        isOpen={isOpenNotification}
        target="noti-popup"
        toggle={toggle}
      >
        <div id="detect-scroll" className="box-noti">
          <div className="con-header">
            <span className="txt-noti-header">Notifications</span>
            {loadingNoti && (
              <div id="loading">
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={32} />
              </div>
            )}
          </div>
          <div style={{ maxHeight: 340, overflowY: 'auto' }}>
            {listNoti.map((noti: NotiData, index: number) => (
              <div
                key={index}
                className="d-flex con-item"
                onClick={() => {
                  setItemIndex(index);
                  onClickItemNoti(noti);
                }}
              >
                <img src={noti?.actor?.avatar} style={{ width: 55, height: 55 }} />

                <div className="con-content" style={{ color: noti.is_read === 0 ? 'white' : '#8F9BB3' }}>
                  <span>
                    <span className="txt-author">
                      {noti.actor?.full_name?.length > 12
                        ? `${noti.actor?.full_name?.substr(0, 12)}...`
                        : noti.actor?.full_name}
                    </span>{' '}
                    <span>{parseAction(noti.action_type)}</span>{' '}
                    <span
                      className="txt-project"
                      onClick={e => {
                        e.stopPropagation();
                        setItemIndex(index);
                        onClickItemNoti(noti);
                        if (router.query.projectid !== noti.project_encoded_id) {
                          router.push(`/${noti.project_encoded_id}`);
                        }
                      }}
                    >
                      {noti.project_name?.length > 15 ? `${noti.project_name?.substr(0, 15)}...` : noti.project_name}
                    </span>
                  </span>

                  {noti.action_description && (
                    <Collapse isOpen={index === itemIndex} className="txt-desc">
                      {noti.action_description}
                    </Collapse>
                  )}
                  <div className="txt-time">{moment(new Date(parseInt(noti.creation_date))).fromNow()}</div>
                </div>

                <div className="d-flex align-items-end" style={{ position: 'relative' }}>
                  {noti.is_read === 0 && <div className="dot-unread" />}

                  <div
                    onClick={e => {
                      e.stopPropagation();
                      onClickDeleteNoti(noti);
                    }}
                    className="icon-remove"
                  >
                    <IconTrash />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {listNoti.length === 0 && (
            <div style={{ height: 340 }} className="d-flex flex-column justify-content-center align-items-center">
              {/* <img src="/img/icon-no-noti.png" /> */}
              <div className="txt-no-noti">All clear 🎉</div>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default Notification;
