import React, { useContext, useRef, useState } from 'react';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { Button } from 'reactstrap';
import Bin from './Bin';
import Import from './Import';
import { sideBarStore } from '../../context/SideBarContext';
import { useQuery } from '@apollo/client';
import { GET_LANGUAGE_TRANSCRIBE, GET_DICTIONARY } from '../../queries';
import { GET_IS_OPEN_LIST_DICT } from '../../reactivities/operations/queries/dictionary';
import { updateDictionaryMutations } from '../../reactivities/operations/mutations/dictionary';

type SidebarProps = {
  showLoaded?: boolean;
  showImport?: boolean;
  showDictionary?: boolean;
  loaded?: ImportedMedia[];
  totalMedia?: ImportedMedia[];
  mediaFolders?: ProjectFolder[];
  projectId?: string;
  userHasWritePermission?: boolean;
  currentMedia?: ImportedMedia;
  isCheckCreditAgain?: boolean;
  handleChangeSelected?: (selectedMedia: ImportedMedia) => void;
  handleCreateMediaFolder?: (folderName: string, callback: Function) => void;
  handleAmendMedia?: (mediaId: string, folderId: string | null) => void;
  openModalConfirmDeleteMedia?: (media: ImportedMedia) => void;
  handleAmendMediaFolder?: (folderId: string, folderName: string) => void;
  handleArchiveMediaFolder?: (folder: ProjectFolder) => void;
  refetchProject?: () => void;
  onOpenModalPayment?: (media: ImportedMedia) => void;
};

enum TranscribeStatus {
  TRANSCRIBED = "transcribed",
  TRANSCRIBING = "transcribing",
  REJECTED = "rejected",
  FAILED = "failed",
  TRANSLATED = "translated",
  TRANSLATING = "translating",
  READY = "ready",
  TRANSCRIBE_PAID = "transcribe_paid",
  TRANSLATE_PAID = "translate_paid",
  UPLOADING = "uploading",
  TRANSCODING = "transcoding"
}

const Sidebar = ({
  showLoaded = true,
  showImport = true,
  showDictionary,
  loaded,
  totalMedia,
  mediaFolders,
  projectId,
  userHasWritePermission,
  currentMedia,
  isCheckCreditAgain,
  handleChangeSelected,
  openModalConfirmDeleteMedia,
  handleCreateMediaFolder,
  handleAmendMedia,
  handleAmendMediaFolder,
  handleArchiveMediaFolder,
  refetchProject,
  onOpenModalPayment,
}: SidebarProps) => {
  const {
    listFileImport,
    lastTranscibe,
    setListFileMedia,
    setProjectId,
    setAccessTokenGD,
    handleLoadFiles,
    onSubUploadFile,
    abortUploadFile,
    onStartTranscribe,
    onResultTranscribe,
    setLastTranscribe,
    onStartTranslate,
    onTranscribeDone,
    onChargeCreditBefore,
    onCloseModalInsufficientCredit,
    onAmendMedia,
    onSubTranslateFile,
  } = useContext(sideBarStore);

  const [loadedPanelActive, setLoadedPanelActive] = useState(false);
  const [importPanelActive, setImportPanelActive] = useState(false);
  const [listLanguageRegion, setListLanguageRegion] = useState<LanguageRegion[]>([]);
  const simonEyeRef = useRef<HTMLElement>(null);
  const sidebarRef = React.createRef<HTMLElement>();

  const isOpen = loadedPanelActive || importPanelActive;

  const {data: listDictData } = useQuery(GET_IS_OPEN_LIST_DICT);
  const { data: languagesData } = useQuery<{ getLanguage: LanguageResponse }, never>(GET_LANGUAGE_TRANSCRIBE);

  const { data: dictionaryData } =
    useQuery<{ getDictionary: DictionaryResponse[] }, { languageId: string | null }>
      (GET_DICTIONARY, { variables: { languageId: null }, });

  useEffect(() => {
    setProjectId(projectId || '');
  }, []);

  useEffect(() => {
    if (isCheckCreditAgain && currentMedia && currentMedia.languages && currentMedia.typeJob) {
      console.log('isCheckCreditAgain');
      onChargeCreditBefore(currentMedia, currentMedia?.languages, currentMedia?.typeJob);
    }
  }, [isCheckCreditAgain]);

  useEffect(() => {
    if (totalMedia && mediaFolders) {
      const temp = [...totalMedia];
      mediaFolders.map(folder => {
        folder.imported_media.map(media => {
          temp.push(media);
        });
      });
      setListFileMedia(temp);
    }
  }, [totalMedia, mediaFolders]);

  useEffect(() => {
    const languagesDataTemp = languagesData?.getLanguage;

    if (
      !languagesDataTemp ||
      !languagesDataTemp.languages ||
      languagesDataTemp.languages.length === 0
    ) {
      return;
    }
      

    languagesDataTemp.languages.map(language => {
      if (!language.language_regions) return;

      language.language_regions.map(region => {
        setListLanguageRegion(prev =>
          prev.concat({
            ...region,
            language,
          })
        );
      });
    });

    if (!languagesDataTemp.last_used_transcription_language_region) return;

    const latestTranscription = languagesDataTemp.last_used_transcription_language_region;

    setLastTranscribe(latestTranscription);
  }, [languagesData]);

  useEffect(() => {
    (function loop() {
      const rand = Math.round(Math.random() * (3000 - 500)) + 1000;
      setTimeout(function () {
        simonBlink(rand, loop);
      }, rand);
    })();
  }, []);

  // using class toggle instead of conditional rendering to enable animation
  useEffect(() => {
    if (isOpen) {
      sidebarRef.current && sidebarRef.current.classList.add('active');
    } else {
      sidebarRef.current && sidebarRef.current.classList.remove('active');
    }
  }, [sidebarRef, isOpen]);

  // handle click outside sidebar
  useEffect(() => {
    const handleClickOutsideSidebar: EventListener = (event: Event): void => {
      if (document.getElementById('modal-payment') || document.getElementById('modal-add-credit')) return;
      if (event.target && sidebarRef.current && !sidebarRef.current.contains(event.target as HTMLElement)) {
        setLoadedPanelActive(false);
        setImportPanelActive(false);
      }
    };
    document.addEventListener('click', handleClickOutsideSidebar);
    return () => {
      document.removeEventListener('click', handleClickOutsideSidebar);
    };
  }, [sidebarRef]);

  function simonBlink(time: number, callBack?: Function) {
    if (simonEyeRef?.current) {
      if (simonEyeRef.current.classList.contains('js-blink')) return;
      simonEyeRef.current.classList.add('js-blink');
      setTimeout(() => {
        if (simonEyeRef?.current) simonEyeRef.current.classList.remove('js-blink');
        if (callBack) callBack();
      }, time + 500);
    }
  }

  const toggleVideo = () => {
    setImportPanelActive(false);
    setLoadedPanelActive(!loadedPanelActive);
  };

  const toggleFolder = () => {
    setImportPanelActive(!importPanelActive);
    setLoadedPanelActive(false);
  };

  const handleMediaClicked = (media: ImportedMedia): void => {
    setLoadedPanelActive(false);
    setImportPanelActive(false);
    if (handleChangeSelected) {
      handleChangeSelected(media);
    }
  };

  const onImportSuccesful = (_data: ImportSuccesfulData): void => {
    setLoadedPanelActive(true);
    setImportPanelActive(false);
  };

  function onAuthenticateGD(token: string) {
    console.log('onAuthenticateGD', token);
    setAccessTokenGD(token);
  }

  function getNewFolders() {
    return mediaFolders?.map(folder => {
      const tempFolder = { ...folder };

      const temp = listFileImport.filter(item => item.project_folder_id === folder.id);
      tempFolder.imported_media = temp;

      return tempFolder;
    });
  }

  function toggleDictionary() {
    if (listDictData.isOpenListDict == false) {
      updateDictionaryMutations.updateIsOpenListDict(true);
      const element = document.getElementById('container-dictionary') as HTMLElement;
      if(element) element.click();
    } else {
      updateDictionaryMutations.updateIsOpenListDict(false);
    }
  }

  return (
    <nav id="sidebar" ref={sidebarRef}>
      <ul className="list-unstyled sidebar-items">
        <li onMouseEnter={() => simonBlink(0)}>
          <div>
            <div title="Back to dashboard" className="sidebar-icon sidebar-logo">
              <Link href="/">
                <a>
                  <img src="/simonsays-no-eye-logo.png" className="logo" alt="Simon Says" />
                  <span ref={simonEyeRef} className="sidebar-logo-eye blink"></span>
                </a>
              </Link>
            </div>
            <div className="sidebar-text sidebar-top"></div>
          </div>
        </li>
        <React.Fragment>
          {showLoaded && (
            <li title="Bin of project media">
              <div onClick={toggleVideo} className={`sidebar-row ${loadedPanelActive ? 'selected' : ''}`}>
                <div className="sidebar-icon">
                  <Button color="link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 100 100"
                      x="0px"
                      y="10px"
                      className="svg-inline--fa fa-file-upload fa-w-12 "
                      width="16"
                      height="16"
                    >
                      <path
                        fill="currentColor"
                        d="M42,82H58a8,8,0,0,0,8-8V70a4,4,0,0,0-8,0v4H42V70a4,4,0,0,0-8,0v4A8,8,0,0,0,42,82Z"
                      />
                      <path
                        fill="currentColor"
                        d="M97.79,52.73,84.53,12.94A16,16,0,0,0,69.35,2H30.65A16,16,0,0,0,15.47,12.94L2.21,52.73a2.89,2.89,0,0,0-.08.65A3.25,3.25,0,0,0,2,54V78A20,20,0,0,0,22,98H78A20,20,0,0,0,98,78V54a3.25,3.25,0,0,0-.13-.62A2.89,2.89,0,0,0,97.79,52.73ZM23.06,15.47A8,8,0,0,1,30.65,10h38.7a8,8,0,0,1,7.59,5.47L88.45,50H11.55ZM90,78A12,12,0,0,1,78,90H22A12,12,0,0,1,10,78V58H90Z"
                      />
                      <path fill="currentColor" d="M30,42H70a4,4,0,0,0,0-8H30a4,4,0,0,0,0,8Z" />
                      <path fill="currentColor" d="M34,26H66a4,4,0,0,0,0-8H34a4,4,0,0,0,0,8Z" />
                    </svg>
                  </Button>
                </div>
                <div className="sidebar-text">
                  <span>BIN</span>
                </div>
              </div>
            </li>
          )}
        </React.Fragment>
        <React.Fragment>
          {showImport && (
            <li title="Import media">
              <div onClick={toggleFolder} className={`sidebar-row ${importPanelActive ? 'selected' : ''}`}>
                <div className="sidebar-icon">
                  <Button color="link">
                    <FontAwesomeIcon icon={faFileUpload} width={24} height={16} />
                  </Button>
                </div>
                <div className="sidebar-text">
                  <span>IMPORT</span>
                </div>
              </div>
            </li>
          )}
        </React.Fragment>
        {showDictionary && <li title="">
          <div
            onClick={toggleDictionary}
            className={`sidebar-row ctn-dict-btn ${listDictData.isOpenListDict ? 'selected' : ''}`}
          >
            <div className="sidebar-icon">
              <Button color="link">
                <FontAwesomeIcon icon={faBook} width={24} height={16} />
              </Button>
            </div>
          </div>
        </li>}
        <li className="sidebar-question-mark sidebar-row">
          <a href="https://www.simonsaysai.com/collections/2667891-simon-says-assemble" target="_blank" rel="noreferrer">
            <div className="sidebar-icon">
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <title>Help</title>

                <g>
                  <title>background</title>
                  <rect fill="none" id="canvas_background" height="102" width="102" y="-1" x="-1" />
                </g>
                <g>
                  <path
                    id="svg_1"
                    d="m50,9a41,41 0 1 1 -41,41a41,41 0 0 1 41,-41m0,-9a50,50 0 1 0 50,50a50,50 0 0 0 -50,-50z"
                  />
                  <path
                    id="svg_2"
                    d="m49.73,60.61l-3.65,0a1.12,1.12 0 0 1 -1.21,-1.15a37.71,37.71 0 0 1 0.74,-9.36a8.53,8.53 0 0 1 2.25,-4c1,-1.06 2.08,-2 3.16,-3s2.37,-2.1 3.59,-3.1c2.4,-2 2.11,-5.48 -0.89,-7.12a7,7 0 0 0 -9.8,3.45a13.7,13.7 0 0 0 -0.6,1.88c-0.24,0.87 -0.64,1.18 -1.54,1.07l-7.13,-0.86a1.26,1.26 0 0 1 -1.18,-1.52a14.61,14.61 0 0 1 12.1,-12.9a20.73,20.73 0 0 1 12.59,1.16a13.59,13.59 0 0 1 7.65,8a10.58,10.58 0 0 1 -1.66,10.56a37.87,37.87 0 0 1 -5.76,5.63c-0.67,0.58 -1.31,1.2 -2,1.77a4.52,4.52 0 0 0 -1.65,3.65c0,1.53 -0.1,3.05 -0.14,4.58a1.23,1.23 0 0 1 -1.35,1.35l-3.52,0l0,-0.09z"
                  />
                  <path id="svg_3" d="m54.89,71.5a5,5 0 1 1 -5,-5a5,5 0 0 1 5,5z" />
                </g>
              </svg>
            </div>
          </a>
        </li>
      </ul>
      <Bin
        loadedPanelActive={loadedPanelActive}
        mediaFolders={getNewFolders()}
        loaded={listFileImport.filter(item => item.project_folder_id === null)}
        languages={languagesData?.getLanguage?.languages || []}
        languageRegions={listLanguageRegion}
        lastTranscibe={lastTranscibe}
        dictionarys={dictionaryData?.getDictionary || []}
        projectId={projectId}
        userHasWritePermission={userHasWritePermission}
        handleChangeSelected={handleMediaClicked}
        handleCreateMediaFolder={handleCreateMediaFolder}
        handleAmendMedia={(mediaId: string, folderId: string | null) => {
          onAmendMedia(mediaId, folderId);
          if (handleAmendMedia) handleAmendMedia(mediaId, folderId);
        }}
        handleAmendMediaFolder={handleAmendMediaFolder}
        openModalConfirmDeleteFolder={(folder: ProjectFolder) => {
          if (handleArchiveMediaFolder) {
            // setLoadedPanelActive(false);
            // setImportPanelActive(false);
            handleArchiveMediaFolder(folder);
          }
        }}
        openModalConfirmDeleteMedia={(media: ImportedMedia) => {
          if (openModalConfirmDeleteMedia) {
            // setLoadedPanelActive(false);
            // setImportPanelActive(false);
            openModalConfirmDeleteMedia(media);
          }
        }}
        handleLoadFiles={handleLoadFiles}
        onAuthenticateGD={onAuthenticateGD}
        onStartTranscribe={onStartTranscribe}
        onResultTranscribe={onResultTranscribe}
        abortUploadFile={abortUploadFile}
        onSubUploadFile={onSubUploadFile}
        onSubTranslateFile={onSubTranslateFile}
        onStartTranslate={onStartTranslate}
        onTranscribeDone={onTranscribeDone}
        onChargeCreditBefore={onChargeCreditBefore}
        onOpenModalPayment={onOpenModalPayment}
        onCloseModalInsufficientCredit={onCloseModalInsufficientCredit}
      />
      {importPanelActive && <Import onImportSuccesful={onImportSuccesful} projectId={projectId} />}
    </nav>
  );
};

export default Sidebar;
